
import { ProductVariation } from '@/data/product-variation/types'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    productVariation: {
      type: Object as PropType<ProductVariation>,
      required: true,
    },
  },
  computed: {
    statusStyle(): string {
      return this.productVariation.unavailable
        ? 'status--inactive'
        : 'status--active'
    },
  },
  methods: {
    formatCurrency(value: string): string {
      return Number(value).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })
    },
  },
})
