
import { defineComponent, inject, PropType } from 'vue'
import { debounce } from 'debounce'
import { DEBOUNCE_INTERVAL } from '@/config'

import {
  GetProductVariationsParams,
  ProductVariation,
} from '@/data/product-variation/types'
import { ProductVariationRepositoryKey } from '@/data/injectables'

import { useToast } from '@/util/toast'
import { useErrorHandler } from '@/util/error-handler'

import ProductVariationListItem from '@/views/product/ProductVariationListItem.vue'
import SearchNavBar from '@/components/navbar/SearchNavBar.vue'
import ActionBar from '@/components/ActionBar.vue'
import ActionButton from '@/components/ActionButton.vue'
import ListWrapper from '@/components/ListWrapper.vue'

export default defineComponent({
  components: {
    SearchNavBar,
    ActionBar,
    ActionButton,
    ListWrapper,
    ProductVariationListItem,
  },
  props: {
    productId: {
      type: Number as PropType<number>,
      required: true,
    },
  },
  setup: () => {
    const productVariationRepository = inject(ProductVariationRepositoryKey)
    const toast = useToast()
    const { handleError } = useErrorHandler(toast)
    return { productVariationRepository, handleError }
  },
  data() {
    return {
      loading: false,
      error: false,
      searchText: '',
      productVariations: [] as ProductVariation[],
    }
  },
  watch: {
    searchText() {
      this.loading = true
      this.loadProductsDebounced()
    },
  },
  created() {
    this.loadProductVariations()
  },
  methods: {
    handleEdit(productVariationId: string): void {
      this.$router.push({
        path: `/products/${this.productId}/variations/${productVariationId}/edit`,
      })
    },
    handleCreate(): void {
      this.$router.push({
        path: `/products/${this.productId}/variations/create`,
      })
    },
    async loadProductVariations(): Promise<void> {
      try {
        this.error = false
        this.loading = true
        const params: GetProductVariationsParams = {
          productId: this.productId,
          size: this.searchText || undefined,
        }
        const productVariations =
          await this.productVariationRepository?.getProductVariations(params)
        this.productVariations = productVariations || []
      } catch (e) {
        this.error = true
        this.handleError(e)
      } finally {
        this.loading = false
      }
    },
    loadProductsDebounced: debounce(function (this: {
      loadProductVariations: () => void
    }) {
      this.loadProductVariations()
    },
    DEBOUNCE_INTERVAL),
  },
})
